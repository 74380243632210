@media screen and (max-width: 992px) {
  // .Sider {
  //   .info-card {
  //     margin-top: 0px !important;
  //   }
  // }
}

// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}
.auth-Page {
  background-color: #f8f7fc;
}

.page {
  height: 75vh;
}
.dashboardCard {
  margin-bottom: 8px;
  height: 35vh;
}
.progress {
  border-radius: 0 !important;
  height: 1.05rem;
}
.bar-chart {
  height: 30%;
  width: 100%;
}
.inner-Bar {
  width: 74%;
  height: 70%;
}
.bar-percent {
  color: #5138ee;
}
.svg {
  font-size: 35px;
  color: #1c1453;
  margin-top: 3rem;
}
.svg1 {
  color: #d1322e;
  font-size: 35px;
  margin-top: 3rem;
}
.modalText {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}
.alert {
  .alert-danger {
    color: #d1322e;
  }
}
.error-msg {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.successBtn {
  background-color: #1c1453;
  color: white;
  border-radius: 6px;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 10px;
  height: 3rem;
}
.success-icon{
  font-size: 26px;
  color: green;
  margin-right:12px  ;
}

.metrics {
  .ctr {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
  input {
    border: 1px solid #e1dfec;
    border-radius: 8px;
    padding: 10px 4px;
  }
}
.MetricsSlider {
  .rangeslider {
    .rangeslider__handle-tooltip {
      background-color: #5138ee;
      // width: 28px;
      // height: 26px;
    }
    .rangeslider__handle-tooltip:after {
      border-top: 8px solid #5138ee;
    }
    .rangeslider__fill {
      box-shadow: none;
      background-color: #e8e6f1;
    }
  }
  .rangeslider-horizontal {
    .rangeslider__handle {
      left: 8.5px !important ;
      width: 21px;
      height: 21px;
      background-color: #5138ee;
      box-shadow: none;
    }
    .rangeslider__handle:after {
      display: none;
    }
    height: 6px;
    .rangeslider__fill {
      background-color: #5138ee;
    }
  }
}

.industryAdd {
  background: #ffffff;
  border: 1px solid #e1dfec;
  border-radius: 8px;
  height: 3.5vh;
}
.cursor-default {
  cursor: default !important;
}

.back-btn {
  .arrowbtn {
    color: #5b5675;
    font-size: 22px;
    width: 15px;
    margin-right: 5px;
  }
  p {
    color: #5b5675;
    font-size: 18px;
    font-weight: 500;
    margin-top: 2px;
  }
}

.header-btn {
  color: #5138ee !important;
  p {
    font-size: 26px;
  }
  span {
    font-size: 12px;
    margin-top: 9px;
    margin-left: 8px;
    font-weight: 500;
  }
}
.header-text {
  font-size: 22px;
  font-weight: 600;
  margin-top: 8px;
  color: black;
}
.main-header {
  .Btn1 {
    background-color: #ffffff;
    color: #262d3d;
    margin-top: 7px;
    height: 90%;
    width: 100%;
    font-weight: 500;
    font-size: 17px;
    border-radius: 6px;
    border: 2px solid #8c87a6;
  }
  .Btn2 {
    margin-top: 8px;
    height: 90%;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
      #5138ee;
    color: white;
    font-weight: 500;
    font-size: 17px;
    box-shadow: 0px 94px 114px -56px rgba(43, 29, 103, 0.08);
    border-radius: 6px;
    border: 0;
  }
}
.overview {
  .card-body {
    box-shadow: 0px -7px 22px #1411250f;
    border-radius: 16px;
  }
}
.totalTransaction-card {
  background-color: #f8f8f8;
}
.card {
  .rate-desc {
    color: #5b5675;
    font-weight: 500;
    font-size: 17px;
  }
  .rate {
    margin-left: 8px;
    font-weight: 800;
    font-size: 40px;
    color: #141125;
  }
  .response-rate {
    font-weight: 500;
    font-size: 12px;
    margin-top: 8px;
    color: #5b5675;
  }
  .ReactApexChart {
    color: #5138ee;
  }
  .pieChart {
    margin-bottom: 4rem;
  }
  .transaction-card {
    background: "#FFFFFF";
    border-radius: 15px;
  }
}

.main-card {
  margin-top: 10rem;
  display: flex;
  justify-content: center;
}
.signUp-form {
  .card {
    height: 33rem;
    box-shadow: 0px 8px 60px rgba(100, 116, 139, 0.15);
    border-radius: 16px;
    width: 34rem;
    h3 {
      color: #101010;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 1.25rem;
    }
    p {
      margin-bottom: 8px !important;
      color: rgba(0, 0, 0, 1);
      font-weight: 500;
      margin-top: 1rem !important;
    }
    input {
      height: 47px;
      background: #ffffff;
      border: 1px solid #dedde4;
      border-radius: 7px;
      width: 100%;
    }
    .loginBtn {
      box-shadow: 0px 94px 114px -56px rgba(43, 29, 103, 0.08);
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        #5138ee;
      color: white;
      width: 100%;
      height: 40px;
      border-radius: 8px;
    }
  }
}
.Sider {
  // .info-card {
  //   margin-top: 250px;
  //   margin-bottom: 50px;
  // }
  .ulColor {
    color: #5138ee;
  }

  h2 {
    font-weight: 500;
    margin-bottom: 1rem;
  }
  ul {
    padding-top: 3rem;
    li {
      padding-top: 2rem;
      font-weight: 700;
      font-size: 14px;
      color: #8c87a6;
    }
  }
  .card {
    border-radius: 35px;
    background-color: rgba(249, 189, 100, 0.08);
    h5 {
      color: #a96b0f;
    }
    p {
      font-weight: 450;
      font-size: 14px;
      color: #a96b0f;
    }
  }
}
.industry {
  font-weight: 550;
  color: #260e62;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.desg {
  font-weight: 450;
  color: #5b5675;
  font-size: 15px;
  margin-top: 8px;
}
.input-inner-text {
  font-weight: 450;
  // margin-top: 8px;
  color: #000000;
}
.percentIcon {
  padding-right: 15px;
  background: url("./logos/percentIcon.svg") no-repeat right;
  background-size: 30px;
}
.detail {
  font-weight: 450;
  font-size: 15px;
  color: #141125;
  margin-top: 8px;
}
.createMain {
  .nameInput {
    ::placeholder {
      color: #bcb8d0;
      opacity: 0.85;
    }
    outline: none;
    border: none;
    color: #bcb8d0;
    font-weight: 600;
    opacity: 0.85;
  }
  height: auto;
  background-color: white !important;
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.createCampaign {
  .addIndustry {
    .select {
      .css-2b097c-container {
        z-index: 3;
      }
    }
  }
  .select {
    .css-2b097c-container {
      z-index: 2;
    }
  }
  .bd {
    border-left: 2px dashed black;
    height: 574px;
    position: absolute;
    left: 0%;
  }
  .slider {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  .Objectives {
    margin: 3rem 0;
  }
  .Industry {
    margin: 3rem 0;
  }
  .Experience {
    margin: 5rem 0;
  }
  .pageText {
    font-family: "Outfit", sans-serif;
    color: #141125;
    font-weight: 600;
    font-size: 16px;
    small {
      font-size: 10px;
      margin-top: 2px;
      margin-left: 2px;
    }
  }
  .heading-text {
    font-weight: 450;
    font-size: 16px;
    color: #5b5675;
  }
  .percent-text {
    font-weight: 450;
    font-size: 16px;
    color: #141125;
    margin-left: 20%;
  }
  .form-select {
    border: 1px solid #e1dfec;
    border-radius: 8px;
    width: 50%;
    height: 44px;
  }
  .btn-active {
    background-color: #5138ee !important;
    color: white !important;
  }
  .btn-default {
    background: #ffffff;
    border: 1px solid #e1dfec;
    border-radius: 8px;
    width: 10rem;
    height: 3rem;
    margin-right: 15px;
  }
  .input-fields {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    input {
      border: 1px solid #e1dfec;
      border-radius: 8px;
      padding: 10px 16px;
      width: 90%;
    }
    .MuiInput-underline:before {
      display: none;
    }
    .MuiInput-underline:after {
      display: none;
    }
  }
  .sem-bdgt {
    padding: 3rem 0;
  }

  .card {
    box-shadow: 5px 3px 16px rgba(20, 17, 37, 0.03);
    border-radius: 16px;
  }
  .btn {
    background: rgba(81, 56, 238, 0.06);
    border-radius: 6px;
    color: #260e62;
    font-weight: 600;
    width: 100%;
    max-width: 120px;
    height: 40px;
    font-weight: 500;
    font-size: 15px;
  }
}

.slider {
  .amount {
    color: #5138ee;
    font-weight: 500;
    font-size: 16px;
  }

  .multi-range-slider {
    display: flex !important;
    position: static !important;
    border: 0 !important;
    padding: 0;
    flex-direction: column;
    user-select: none;
    box-shadow: none;
    .bar-right {
      box-shadow: none;
      background: rgba(81, 56, 238, 0.06);
    }
    .bar-left {
      box-shadow: none;
    }
  }
  .multi-range-slider {
    .bar {
      margin: 6px 0px 3px 0px;
    }
  }
  .multi-range-slider .bar-inner {
    background-color: #5138ee !important;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: space-between;
    position: relative;
    justify-content: space-between;
    box-shadow: none;
    border: none;
  }
  .multi-range-slider .thumb::before {
    content: "< >";
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    color: white;
    background: #1c0b83;
    box-shadow: 0px -2px 40px rgba(134, 134, 134, 0.2);
    border-radius: 24px;
    position: absolute;
    width: 34px;
    height: 20px;
    z-index: 1;
    margin: -7px;
    cursor: pointer;
  }
}
